<template>
  <referentiel-list
    ref="refList"
    :listTitle="'Liste des Catégorie Rome'"
    :referentielName="referentielName"
    :tableFields="tableFields"
  >
    <template #formContent="{ handleInput, editableItem }">
      <div class="row">
        <div class="col-sm-12">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <input
                :value="editableItem.name"
                @input="handleInput"
                type="text"
                class="form-control"
                id="name"
                name="name"
                placeholder="Nom"
              />
              <label for="name">Nom</label>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
        </div>
        <div class="col-sm-12">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <textarea
                :value="editableItem.description"
                @input="handleInput"
                type="text"
                rows="5"
                style="height: 100px"
                class="form-control"
                id="description"
                name="description"
                placeholder="description"
              />
              <label for="description">Description</label>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
        </div>
        <!-- <div class="col-sm-12">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <input
                :value="editableItem.fileName"
                @input="handleInput"
                type="text"
                class="form-control"
                id="fileName"
                name="fileName"
                placeholder="Nom du fichier"
              />
              <label for="fileName">Nom du fichier</label>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
        </div> -->
        <div class="col-sm-12">
          <ValidationProvider rules="" v-slot="{ errors }">
            <div class="form- mb-3">
              <label for="thumbnailUploader">Image</label>
              <FilePondUploader
                ref="thumbnailUploader"
                class="form-control col-12 md:col-12"
                @save="thumbnailSaved(editableItem)"
                :acceptedFileTypes="['image/jpeg', 'image/png', 'image/webp']"
                :autoResize="true"
              />
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
        </div>
        <div class="col-sm-12">
          <ValidationProvider rules="" v-slot="{ errors }">
            <div class="form- mb-3">
              <label for="fileUploader">Fichier Template Word</label>
              <FilePondUploader
                ref="fileUploader"
                class="form-control col-12 md:col-12"
                @save="fileSaved(editableItem)"
                :acceptedFileTypes="[
                  '.docx',
                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                ]"
                :autoResize="true"
                rows="8"
              />
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
        </div>
      </div>
    </template>

    <template #cell="{ data }">
      <div v-if="data.field.key != 'fullThumbnailUrl'">{{ data.value }}</div>
      <div v-else>
        <img style="height: 100px; width: 70px" :src="data.item.fullThumbnailUrl" />
      </div>
    </template>
  </referentiel-list>
</template>

<script>
import ReferentielList from "../../../components/common/referentiel/ReferentielList.vue";
import FilePondUploader from "../../../components/uploader/FilePondUploader.vue";

export default {
  components: {
    ReferentielList,
    FilePondUploader,
  },
  computed: {
    referentielName() {
      return "cvModele";
    },
    emptyReferentielItem() {
      return {
        libelle: null,
        code: null,
        // famille : null
      };
    },
    tableFields() {
      return [
        "#",
        "index",

        { key: "fullThumbnailUrl", label: "Image" },
        { key: "name", label: "Nom" },
        { key: "description", label: "Description" },
        { key: "fileName", label: "Nom du fichier" },

        "actions",
      ];
    },
  },
  methods: {
    fileSaved(editableItem) {
      let fileUrl = this.$refs.fileUploader.getSingleFileUrl();
      editableItem = {
        ...editableItem,
        fileUrl,
      };
      console.log(this.editableItem);
      this.$refs.refList.setActiveReference(editableItem);
    },
    thumbnailSaved(editableItem) {
      let thumbnailUrl = this.$refs.thumbnailUploader.getSingleFileUrl();
      editableItem = {
        ...editableItem,
        thumbnailUrl,
      };

      this.$refs.refList.setActiveReference(editableItem);
    },
  },
};
</script>

<style></style>
